.col-20p {
  flex: 0 0 20%;
  max-width: 20%;
}

@include media-breakpoint-up(sm) {
  .col-sm-20p {
    flex: 0 0 20%;
    max-width: 20%;
  }   
}

//line up
.multiColumnPanel{
  max-width: 1110px;
  margin: auto;
  .container{
    margin-left: auto;
    margin-right: auto;
    @include media-breakpoint-up(xl){
      margin-left: -15px;
      margin-right: -15px;
    }
  }
}