.navBar {
  position: fixed;
  top: map-get($map: $top-bar-height, $key: mobile);
  width: 90%;
  max-width: 400px;
  height: auto;
  background-color: black;
  color: #e86f3a;
  z-index: 999;
  opacity: 0.3;
  transition: $transition-base;
  transform: translateX(100%);
  right: 0;

  @include media-breakpoint-up(md) {
    top: map-get($map: $top-bar-height, $key: tablet);
  }

  @include media-breakpoint-up(lg) {
    top: map-get($map: $top-bar-height, $key: desktop);
  }

  .scrolled &,
  .subpage & {
    top: map-get($map: $top-bar-height, $key: mobile);

    @include media-breakpoint-up(lg) {
      top: map-get($map: $top-bar-height, $key: desktop);
    }
  }
}

.navBar--on {
  transform: translateX(0);
  opacity: 1;
}

// navBar menu list common
.navBar_nav {
  margin: 0;

  ul,
  li {
    margin: 0;
    padding: 0;
    display: block;
    list-style: none;
  }

  li {
    position: relative;
    margin: 0.5rem 0;
  }

  a {
    display: block;
    color: #fff;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    line-height: 1;
    transition-duration: 0.3s;
    text-transform: uppercase;
    // background-color: rgba(#fff, 0.1);

    &:hover,
    &:focus {
      text-decoration: none;
      color: white;
      background-color: $primary-color;
    }
  }
}
