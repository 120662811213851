// overriding bootstrap variables in _boostrap-override.scss
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;500;600;700;800;900;1000&family=Poppins:wght@300;500;600&display=swap');

$family-header: 'Poppins', sans-serif;
$family-base: 'proxima-nova', sans-serif;

// font-size
$font-size-desktop: 21px;
$font-size-mobile: 16px;

$weight-thin: 100;
$weight-light: 300;
$weight-medium: 500;
$weight-normal: $weight-light;
$weight-semibold: 600;
$weight-bold: 700;

// colors - sister colours from bootrap
$color-blue: #007bff;
$color-red: #c20f2f;
$color-green: #28a745;

// more colors
$color-darkblue: #0077bd;
$color-lightblue: #0089a6;
$color-lightgrey: #454345;
$color-orange: #e86f3a;

// site colors
$primary-color: $color-orange;
$font-color: #454345;
$font-color-white: #fff;
$header-color: $primary-color;
$bg-color: #ebebeb;
$link-color: $color-darkblue;
$link-hover-color: $color-lightblue;

// top-bar
$top-bar-border: (
  mobile: 5px,
  desktop: 12px,
);
$top-bar-height: (
  mobile: 44px,
  small-device: 81px,
  tablet: 81px,
  desktop: 81px,
);

// base transition
$transition-base: all 0.3s ease-in-out;
$transition-fast: all 0.2s ease-in-out;
$transition-slow: all 0.5s ease-in-out;
