.big_panel .container {
    @media screen and (min-width: 991px) {
        max-width: 93%;
    }
    @media screen and (min-width: 1199px) {
        max-width: 83%;
    }
    @media screen and (min-width: 1600px) {
        max-width: 87%;
    }
    @media screen and (min-width: 2500px) {
        max-width: 65%;
    }
}
.big_panel .pageTiles__big__css  {
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    @include media-breakpoint-up(xl) {
        padding-left: 25px;
        padding-right: 25px;
        margin-top: 40px;
        margin-bottom: 40px;
    }
}
.pageTile__big {
    width: 100%;
    height: 250px;
    margin-bottom: 20px;
    @include media-breakpoint-up(lg) {
        width: 23%;
        height: 363px;
    }
    @include media-breakpoint-up(xl) {
        height: 333px;
    }
    @include media-breakpoint-up(xxl) {
        flex: 1 0 20%;
        width: 450px;
        height: 580px;
        margin-bottom: 0;
    }
    @media screen and (min-width: 1800px) {
        width: 450px;
        height: 500px;
    }
}
.pageTile__big:hover .pageTile__big__title,
.pageTile__big.selected .pageTile__big__title {
  color: #ffffff;
}
.pageTile__big:hover .pageTile__bsyc__desc,
.pageTile__big.selected .pageTile__bsyc__desc {
  color: #ffffff;
}
.pageTile__big:hover .pageTile_btn,
.pageTile__big.selected .pageTile_btn {
  color: #ffffff;
}
.pageTile__big__wrapper {
    box-shadow: 0.1px 0.1px 6px -2px rgba(0,0,0,.25);
    padding: 20px;
    padding-right: 40px;
    padding-bottom: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: white;
    height: 100%;
    border-radius: 4px;
    border-bottom: 4px solid #166c75;
    @include media-breakpoint-up(xxl) {
        padding: 30px;
        padding-right: 67px ;
        padding-bottom: 0;
    }
}
.pageTile__big__wrapper:hover, .pageTile__big__wrapper:focus, .pageTile__big__wrapper.selected {
    background-color: #43c0b4;
    color: #ffffff;
    border-bottom: 4px solid #43c0b4;
}
.pageTile__big__content:hover, .pageTile__big__content:focus, .pageTile__big__content.selected {
    text-decoration: none;
}
.tile_icons img {
    width: 40px;
    height: 40px;
    @include media-breakpoint-up(xxl) {
        width: 100px;
        height: 100px;
    }
    .normal { display: block; }
    .selected { display: none; }
}
.pageTile__big:hover .tile_icons,
.pageTile__big.selected .tile_icons {
  .normal { display: none; }
  .selected { display: block; }
}
.pageTile__big__title {
    font-size: 20px;
    color: #166c75;
    line-height: 50px;
    font-weight: 600;
    @media screen and (min-width: 1600px) and (max-width: 1655px) {
        font-size: 36px;
        line-height: 54px;
        margin-bottom: 1.5rem;
    }
    @media screen and (min-width: 1656px) {
        font-size: 36px;
        line-height: 70px;
        margin-bottom: 0;
    }
}
.pageTile__bsyc__content__details .tiffanyGreen {
    color: #43c0b4;
}
.pageTile__bsyc__content__details .waterGreen {
    color: #7fb4b9;
}
.pageTile__bsyc__desc {
    font-size: 13px;
    color: #232323;
    @include media-breakpoint-up(xxl) {
        font-size: 19px;
    }
}
.pageTile__big__button {
    margin-top: 10px;
    @include media-breakpoint-up(xxl) {
        margin-top: 30px;
    }
}
.pageTile_btn {
    font-size: 11px;
    color: #166c75;
    font-weight: 700;
    padding: 0;
    transition: none;
    @include media-breakpoint-up(xxl) {
        font-size: 15px;
    }
}
.pageTile__special .first__item, .pageTile__special .second__item {
    gap: 0;
    @include media-breakpoint-up(lg) {
        gap: 1rem;
    }
    @include media-breakpoint-up(xl) {
        gap: 2rem;
    }
    @include media-breakpoint-up(xxl) {
        gap: 3rem;
    }
}
.pageTiles__special {
    @media screen and (min-width: 1656px) {
        margin-left: -350px !important;
        margin-right: -350px !important;
    }
}