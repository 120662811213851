@import './mixins';
@import './variables';

// plugins
@import './bootstrap/bootstrap';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
//@import '~react-day-picker/lib/style.css';
@import '~react-modal-video/scss/modal-video.scss';

//@import './fonts';
@import './base';
@import './sections';
@import './form';

// top
//@import './layout/hamburger';
//@import './layout/top';
@import './layout/top-navbar';

// big panel
@import './layout/bigPanel/paneltile';

// bottom
@import './layout/bottomPanel';
@import './layout/bottomMenu';

@import './cms/cms-site';

// modules
@import './modules/breadcrumb';
@import './modules/rotatingBanner';
@import './modules/bannerSize';
@import './modules/pageTiles';
//@import './modules/blogTiles';
@import './modules/slider';
@import './modules/multiColumnPanel';

// components
@import '../components';

@import './site/index';

//wave
@import './modules/wave/wave';

//aos
@import '~aos/src/sass/aos';

@import './youtubeNew.scss';

// react-image-gallery
@import '~react-image-gallery/styles/css/image-gallery.css';

@media print {
  @import './print';
}

//banner-text-bar
section.banner-text-bar {
  .htmlPanel {
    p {
      margin-bottom: 0px;
      text-align: center;
    }
  }
}

.choose-file-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  label.choose-file-label {
    background-color: white;
    margin-bottom: 0px !important;
    padding: 0.3rem 0.5rem;
    border-radius: 0.25rem;
    font-weight: 600;
  }
  div.file__attach__text {
    margin-left: 1rem;
  }
}

body:not(.cmsedit) {
  div.cms_item.desktop-ig-feed {
    display: none;
    @include media-breakpoint-up(sm) {
      display: block;
    }
  }
  div.cms_item.mobile-ig-feed {
    display: block;
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.Masthead-Theme-2 .topHeader_style_scrolled {
  background-color: black;
}
.topHeader_style .masthead_2,
.topHeader_style_scrolled .masthead_2 {
  display: none;
}
.Masthead-Theme-1 .masthead_1 {
  display: block;
}
.Masthead-Theme-2 .masthead_1 {
  display: none;
}
.Masthead-Theme-2 .masthead_2 {
  display: block;
}

.contentPanel a.secondary {
  background: #0089a6;
}
.contentPanel a.secondary:hover,
.contentPanel a.secondary:focus,
.contentPanel a.secondary:active {
  background: #0077bd;
}
.main-content {
  overflow: hidden;
}

.banner-item {
  position: relative;
}
.bg_dark_linear {
  width: 100%;
  height: 25%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
}
.pageTile__special {
  @media screen and (max-width: 576px) {
    padding: 10px !important;
  }
}

// .rotatingReviewItems .slick-prev,
// .rotatingReviewItems .slick-next {
//   display: none !important;
// }

// html {
//   scroll-behavior: smooth;
// }

// react-image-gallery
.photoSlide .image-gallery-right-nav,
.photoSlide .image-gallery-left-nav {
  padding: 10px 10px;
  background-color: rgba(0, 0, 0, 0.4);
}
.photoSlide .image-gallery-left-nav .image-gallery-svg,
.photoSlide .image-gallery-right-nav .image-gallery-svg {
  width: 32px;
  height: 51px;
  @media screen and (max-width: 576px) {
    width: 13px;
    height: 32px;
  }
}
.photoSlide .image-gallery-slide .image-gallery-image {
  object-fit: cover;
  aspect-ratio: 1.7;
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {
  aspect-ratio: 1.7;
}
.photoSlide .fslightbox-toolbar-button.fslightbox-flex-centered:first-child {
  display: none;
}
:not(:root):fullscreen::backdrop {
  height: 100%;
}
.fslightbox-open .cms_item.photoSlide {
  height: 100vh;
}
.fslightbox-open .cms_item.photoSlide .fslightbox-container {
  top: 81px;
  @media screen and (max-width: 576px) {
    top: 44px;
  }
}
.fslightbox-open .cms_item.photoSlide .fslightbox-container .fslightbox-source {
  transform: translateY(-57px);
}
.sliderAwardPanel button.slick-arrow.slick-prev,
.sliderAwardPanel button.slick-arrow.slick-next {
  @media screen and (max-width: 576px) {
    display: none !important;
  }
}
.sliderAwardPanel .slick-list {
  @media screen and (max-width: 576px) {
    margin: 0 30px;
  }
}
.sliderAwardPanel .slick-list .sliderImage {
  @media screen and (max-width: 576px) {
    width: 100%;
  }
}
.testimonials .rotatingReviewItems .slick-prev::before,
.testimonials .rotatingReviewItems .slick-next::before {
  color: white;
}
