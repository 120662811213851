b,
strong {
  font-weight: $weight-bold;
}

a {
  color: $link-color;
  transition: $transition-base;
  &:hover,
  &:focus,
  &:active {
    color: $link-hover-color;
  }
}

h1,
h2,
h3,
h4 {
  font-family: $family-header;
  margin-bottom: 0;
}
h5, h6 {
  font-family: $family-base;
  margin-bottom: 0;
}

h1, h2 {
  font-weight: $weight-semibold;
}
h3 {
  font-weight: $weight-medium;
}
h4 {
  font-weight: $weight-light;
}

// color 
h1, h2 {
  color: $primary-color;
}
h3 {
  color: $color-darkblue;
}
h4, h5, h6 {
  color: $color-lightgrey;
}

// size
h1 {
  font-size: rem(40, $font-size-mobile);
}
h2 {
  font-size: rem(30, $font-size-mobile);
}
h3 {
  font-size: rem(24, $font-size-mobile);
}
h4 {
  font-size: rem(20, $font-size-mobile);
}
h5 {
  font-size: rem(16, $font-size-mobile);
}
h6 {
  font-size: rem(12, $font-size-mobile);
}
@include media-breakpoint-up(lg) {
  h1 {
    font-size: rem(62, $font-size-desktop);
  }
  h2 {
    font-size: rem(46, $font-size-desktop);
  }
  h3 {
    font-size: rem(34, $font-size-desktop);
  }
  h4 {
    font-size: rem(28, $font-size-desktop);
  }
  h5 {
    font-size: rem(16, $font-size-desktop);
  }
  h6 {
    font-size: rem(12, $font-size-desktop);
  }
}

// html editor
.text-tiny {
  font-size: 0.75rem;
}

.text-big {
  font-size: 1.5rem;
}

p {
  margin-bottom: 0;
}